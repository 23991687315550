/* @media (min-width: 600px) {
  .css-1vogq0u-MuiButtonBase-root-MuiTab-root:not(:last-of-type) {
    margin-right: 0 !important;
  }
}
@media (min-width: 600px) {
  .css-1knul12-MuiButtonBase-root-MuiTab-root:not(:last-of-type) {
    margin-right: 0 !important;
  }
}

.css-1knul12-MuiButtonBase-root-MuiTab-root:not(:last-of-type) {
  margin-right: 0 !important;
}
.css-1vogq0u-MuiButtonBase-root-MuiTab-root:not(:last-of-type) {
  margin-right: 0 !important;
}
@media (min-width: 600px) {
  .css-u06bnh-MuiButtonBase-root-MuiTab-root:not(:last-of-type) {
    margin-right: 0 !important;
  }
}
.css-1vogq0u-MuiButtonBase-root-MuiTab-root:not(.Mui-selected) {
  color: black !important;
}

.css-u06bnh-MuiButtonBase-root-MuiTab-root:not(:last-of-type) {
  margin-right: 0 !important;
}
.css-1ounzbb-MuiPaper-root-MuiDialog-paper.MuiPaper-rounded {
  border-radius: 20px !important;
} */

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
